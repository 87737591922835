import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    stripeSettings: {
      publish_key: null,
    },
  },
  getters: {
    getStripeSettings: state => state.stripeSettings,
    getStripePublishKey: state => state.stripeSettings.publish_key,
  },
  mutations: {
    SET_STRIPE_SETTINGS(state, val) {
      state.stripeSettings = val
    },
  },
  actions: {
    async fetchPaymentSettings({ commit }) {
      try {
        const response = await axios.get('payment-settings')
        commit('SET_STRIPE_SETTINGS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
  },
}