import Vue from 'vue'
import axios from '@/libs/axios'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import {
  hasValue,
  designateValue,
  designateOptions,
  transformField,
  checkHiddenAttr,
  checkIfStepCanBeHidden,
} from '@/helpers/formConditions'

const defaultState = () => {
  return {
    type: 'Camper',
    userId: null,
    applicationId: null,
    program: {},
    userAttributes: [],
    userFieldResults: [],
    paymentPlan: null,
    hidePrice: false,
    limitSessionSelection: false,
    sessionsList: [],
    stepsList: [],
    stepsValues: [],
    chosenSessions: [],
    selectedSessionsList: [],
    selectedGroupsList: [],
    applicationStatus: null,
    isApplicationAccepted: false,
    applicationPaymentOption: null,
    paymentContract: {},
    paymentContractIsExist: false,
    allSelectedGroups: [],
    groupsForAutoAssign: [],
    groupsForPC: [],
    currentTabIndex: 0,
    selectedGroupForViewingUsers: null,
    allConditions: [],
    allFormFields: [],
    isDataFullPrepared: true,
    editingApplicationPermissions: [],
    crossProgramsConditions: [],
    crossProgramConditionalFieldsData: [],
    familySettings: [],
    familyFormFields: [],
    familyFormConditions: [],
    familyData: {},
  }
}


export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    getProgramName: state => state.program.name,
    getProgramID: state => state.program.id,
    getProgramCompleteRegistrationBtnText: state => state.program.complete_registration_btn_text,
    getApplicationId: state => state.applicationId,
    getUserId: state => state.userId,
    getUserAttributes: state => state.userAttributes,
    getUserFieldResults: state => state.userFieldResults,
    getPaymentPlan: state => state.paymentPlan,
    getHidePrice: state => state.hidePrice,
    getLimitSessionSelection: state => state.limitSessionSelection,
    getSessionsList: state => state.sessionsList,
    getStepsList: state => state.stepsList,
    getStepsValues: state => state.stepsValues,
    getChosenSessions: state => state.chosenSessions,
    getSelectedSessionsList: state => state.selectedSessionsList,
    getSelectedGroupsListOnlyFormApplication: state => state.selectedGroupsList,
    getAllSelectedGroups: state => state.allSelectedGroups,
    getSelectedGroupsForPC: state => state.allSelectedGroups.filter(group => group.has_tuition_fee && group.price != 0),
    getGroupsForAutoAssign: state => state.groupsForAutoAssign,
    getApplicationStatus: state => state.applicationStatus,
    getIsApplicationAccepted: state => state.isApplicationAccepted,
    getApplicationPaymentOption: state => state.applicationPaymentOption,
    getPaymentContract: state => state.paymentContract,
    getPaymentContractIsExist: state => state.paymentContractIsExist,
    getCurrentTabIndex: state => state.currentTabIndex,
    getSelectedGroupForViewingUsers: state => state.selectedGroupForViewingUsers,
    getAllConditions: state => state.allConditions,
    getAllFormFields: state => state.allFormFields,
    getFilteredStepsList: state => state.stepsList.filter(step => !step.hidden),
    getHiddenStepsIds: state => state.stepsList.filter(step => step.hidden).map(step => ({
      stepId: step.id,
      hiddenFromStepId: step.hiddenByStep
    })),
    getLastStep: state => state.stepsList.filter(step => !step.hidden).slice(-1)[0],
    getIsDataFullPrepared: state => state.isDataFullPrepared,
    getEditingApplicationPermissions: state => state.editingApplicationPermissions,
    getCrossProgramsConditions: state => state.crossProgramsConditions,
    getIsExistCrossProgramsConditions: state => state.crossProgramsConditions.length > 0,
    getCrossProgramConditionalFieldsData: state => state.crossProgramConditionalFieldsData,
    getFamilySettings: state => state.familySettings,
    getIsUseFamilyForm: state => state.familySettings?.use_family_form,
    getFamilyFormFields: state => state.familyFormFields,
    getFamilyFormConditions: state => state.familyFormConditions,
    getFamilyData: state => state.familyData,
    getIsFilledFamilyForm: state => state.familyData.family?.filled_form,
    getIsConfirmedFamilyForm: state => state.familyData.family?.form_status === 'Approved',
    getFamilyFormStatus: state => state.familyData.family?.form_status,
  },
  mutations: {
    SET_USER_ID: (state, data) => {
      state.userId = data
    },
    SET_APPLICATION_ID: (state, data) => {
      state.applicationId = data
    },
    SET_PROGRAM_ID: (state, data) => {
      state.program.id = data
    },
    SET_TYPE: (state, data) => {
      state.type = data
    },
    SET_USER_ATTRIBUTES: (state, data) => {
      state.userAttributes = data
    },
    SET_USER_FIELD_RESULTS: (state, data) => {
      state.userFieldResults = data
    },
    SET_FAMILY_DATA: (state, data) => {
      state.familyData = data
    },
    SET_IS_FILLED_FAMILY_FORM: (state, data) => {
      state.familyData.family.filled_form = data
    },
    SET_FAMILY_SETTINGS: (state, data) => {
      state.familySettings = data
    },
    SET_FAMILY_FORM_VALUES: state => {
      state.familyFormFields.forEach(field => {
        let value
        if (field.section_type === 'parent'
          && state.familyData.primary_parent
        ) {
          const foundedField = state.familyData.primary_parent.field_results.find(fieldResult => fieldResult.field_id === field.field_id)
          if (foundedField) {
            value = foundedField.result
          } else if (state.familyData.primary_parent.attributes) {
            const fieldName = field.attribute_field_name ? field.attribute_field_name : field.name
            value = state.familyData.primary_parent.attributes[fieldName]
          }
        }

        if (field.section_type === 'secondary_parent'
          && state.familyData.secondary_parent
        ) {
          const foundedField = state.familyData.secondary_parent.field_results.find(fieldResult => fieldResult.field_id === field.field_id)
          if (foundedField) {
            value = foundedField.result
          } else if (state.familyData.secondary_parent.attributes) {
            const fieldName = field.attribute_field_name ? field.attribute_field_name : field.name
            value = state.familyData.secondary_parent.attributes[fieldName]
          }
        }

        if (field.section_type === 'family') {
          const foundedField = state.familyData.family.field_results.find(fieldResult => fieldResult.field_id === field.field_id)
          if (foundedField) {
            value = foundedField.result
          } else if (state.familyData.family.attributes) {
            if (field.attribute_field_name in state.familyData.family.attributes) {
              value = state.familyData.family.attributes[field.attribute_field_name]
            } else {
              value = state.familyData.family.attributes[field.name]
            }
          }
        }

        field.value = designateValue(field.type, value, field.options)
      })
    },
    SET_FAMILY_FORM: (state, data) => {
      state.familyFormConditions = data.conditions
      state.familyFormFields = data.fields.map((field, index) => {
        const options = designateOptions(field.type, field.options)
        const ref = field.name + index

        return {
          id: uuidv4(),
          field_id: field.id,
          step_id: null,
          ref: ref,
          value: designateValue(field.type, field.default, options),
          label: field.label,
          name: field.name,
          options: options,
          type: transformField(field.type),
          required: field.required,
          hidden: checkHiddenAttr(field.conditions.filter(item => item.action === 'Show')),
          use_prefill: field.use_prefill,
          attribute_field_name: field.attribute_field_name,
          section_type: field.section_type,
          readonly: field.readonly,
        }
      })
    },
    ADD_SECONDARY_PARENT: state => {
      const newFields = state.familyFormFields
        .filter(field => field.section_type === 'parent')
        .map(field => {
          return {
            ...field,
            id: uuidv4(),
            value: designateValue(field.type, field.default, field.options),
            section_type: 'secondary_parent',
          }
        })

      state.familyFormFields = [...newFields, ...state.familyFormFields]
    },
    REMOVE_SECONDARY_PARENT: state => {
      state.familyFormFields = state.familyFormFields
        .filter(field => field.section_type !== 'secondary_parent')
    },
    SET_PROGRAM: (state, data) => {
      state.program = data
      state.paymentPlan = data.payment_plan
      state.hidePrice = data.is_hide_price
      state.limitSessionSelection = data.is_only_one_session_selection_allowed
      state.sessionsList = data.sessions.map(item => {
        return ({
          ...item,
          price: Number(item.price),
          front_check: item.allowed_individual_selection
        })
      })
      state.stepsList = data.steps[state.type].map(step => ({
        ...step,
        status: '',
        isDisabled: true,
        isDisabledEditingByPc: false,
        hasPC: step.has_payment_contract && data.payment_contract
      }))
      state.editingApplicationPermissions = data.editing_permissions[state.type]
      state.crossProgramsConditions = data.cross_programs_conditions[state.type]
    },
    SET_EDITABLE_SETTINGS_FOR_STEPS: state => {
      const lastApprovedByAdminStepIndex = state.stepsList.findLastIndex(step =>
        ['Requires Approval', 'Requires Approval To Continue'].includes(step.requirement_option) && step.status === 'Approved')

      state.stepsList.forEach((step, index) => {
        if (state.editingApplicationPermissions.enable_edit_specific_steps &&
          Array.isArray(state.editingApplicationPermissions.specific_steps) &&
          state.editingApplicationPermissions.specific_steps.includes(step.id)
        ) {
          step.isEditable = true
          return
        }

        if (step.isDisabledEditingByPc && step.status !== 'Rejected') {
          step.isEditable = false
          return
        }

        if (lastApprovedByAdminStepIndex && index <= lastApprovedByAdminStepIndex && step.status !== 'Rejected') {
          step.isEditable = false
          return
        }

        if (state.isApplicationAccepted && ['Approved', 'In Review'].includes(step.status)) {
          step.isEditable = false
          return
        }

        if (state.editingApplicationPermissions.enable_edit_all_steps) {
          step.isEditable = true
          return
        }

        if (state.editingApplicationPermissions.disable_editing_whole_application) {
          if (state.editingApplicationPermissions.specific_date) {
            step.isEditable = moment().isBefore(state.editingApplicationPermissions.specific_date)
          } else {
            step.isEditable = false
          }
          return
        }

        if (state.editingApplicationPermissions.disable_editing_steps
          && Array.isArray(state.editingApplicationPermissions.specific_step_statuses)
        ) {
          if (!step.status) {
            step.isEditable = true
            return
          }

          step.isEditable = !state.editingApplicationPermissions.specific_step_statuses.includes(step.status)

          if (!state.editingApplicationPermissions.specific_date) {
            return
          }

          step.isEditable = moment().isBefore(state.editingApplicationPermissions.specific_date)
          return
        }

        step.isEditable = true
      })
    },
    SET_APPLICATION: (state, data) => {
      state.stepsValues = data.application_steps
      state.chosenSessions = data.selected_sessions
      state.selectedSessionsList = data.selected_sessions
      state.selectedGroupsList = data.selected_groups
      state.allSelectedGroups = data.selected_groups
      state.applicationStatus = data.status
      state.isApplicationAccepted = data.is_accepted
      state.applicationPaymentOption = data.application_payment_option
      if (data.application_payment_option) {
        state.paymentContractIsExist = true
      }
    },
    PREPARE_CROSS_PROGRAMS_CONDITIONAL_FIELDS_DATA: state => {
      state.crossProgramsConditions.forEach(condition => {
        condition.scoped_conditions.forEach(subConditions => {
          subConditions.forEach(subCondition => {
            state.crossProgramConditionalFieldsData.push(...[
              {
                id: subCondition.id,
                field_id: subCondition.field_id,
                program_id: subCondition.cross_program_id,
              }
            ])
          })
        })
      })
    },
    PREPARE_CROSS_PROGRAMS_CONDITIONS: state => {
      state.userFieldResults.forEach(userFieldResult => {
          if (userFieldResult.type === 'file_upload') {
            userFieldResult.value = userFieldResult.files.map(item => item.path) || []
            userFieldResult.files = userFieldResult.files || []
          } else if (userFieldResult.type === 'payment_field') {
            userFieldResult.payment_month_selected = userFieldResult.result?.payment_month_selected
            userFieldResult.payment_plan_activated = userFieldResult.result?.payment_plan_activated
          } else {
            userFieldResult.value = designateValue(userFieldResult.type, userFieldResult.result)
          }
      })
    },
    PREPARE_CONDITIONS_FROM_STEPS: state => {
      state.stepsList.forEach(step => {
        if (step.form) {

          state.allFormFields.push(...step.form.fields)

          let stepConditions = []
          step.form.fields.forEach(field => {
            if (field.conditions.length) {
                stepConditions.push(...field.conditions)
            }
          })

          stepConditions.push(...step.step_conditions)
          
          if (checkIfStepCanBeHidden(step)) {
            step.hidden = checkHiddenAttr(step.step_conditions.filter(item => item.action === 'Show'))
          }

          if (stepConditions.length) {
            state.allConditions.push(...stepConditions)
          }
        }
      })
    },
    SET_FROMS_DATA: state => {
      let index = 0
      state.stepsList.filter(step => step.form).forEach(step => {
        step.preparedFields = step.form.fields.map(field => {
          if (field.type === 'file_upload') step.hasFileField = true
          if (field.type === 'signature') step.hasSignatureField = true

          const ref = field.name + index
          index++

          let newField
          if (field.type === 'group_selection') {
            newField = {
              id: field.id,
              step_id: step.id,
              ref: ref,
              value: null,
              label: field.label,
              name: field.name,
              default: field.default,
              options: [],
              type: transformField(field.type),
              required: field.required,
              hide_price: field.hide_price,
              hidden: checkHiddenAttr(field.conditions.filter(item => item.action === 'Show')),
            }
          } else if (field.type === 'payment_field') {
            newField = {
              id: field.id,
              step_id: step.id,
              ref: ref,
              name: field.label,
              type: transformField(field.type),
              charge_amount: field.options.charge_amount,
              description: field.options.description,
              payment_plan_is_enable: field.options.payment_plan_is_enable,
              max_months: field.options.max_months,
              payment_plan_activated: false,
              payment_month_selected: null,
              field_id: field.id,
              hidden: checkHiddenAttr(field.conditions.filter(item => item.action === 'Show')),
            }
          } else {
            newField = {
              id: field.id,
              step_id: step.id,
              ref: ref,
              value: designateValue(field.type, field.default, field.options),
              label: field.label,
              name: field.name,
              options: designateOptions(field.type, field.options),
              type: transformField(field.type),
              required: field.required,
              hidden: checkHiddenAttr(field.conditions.filter(item => item.action === 'Show')),
              use_prefill: field.use_prefill,
              attribute_field_name: field.attribute_field_name,
            }
          }

          return newField
        })
      })
    },
    SET_FORMS_VALUES_FROM_USER_ATTRIBUTES: state => {
      state.userAttributes.forEach(item => {
        state.stepsList.filter(step => step.use_form_prefill).forEach(step => {
          step.preparedFields.filter(field => field.use_prefill).forEach(field => {
            if (field.name === item.key || field.attribute_field_name === item.key) {
              field.value = designateValue(field.type, item.value, field.options)
            }
          })
        })

      })
    },
    SET_FORMS_VALUES_FROM_APPLICATION_FIELDS: state => {
      state.stepsValues.forEach(stepWithValues => {
        state.stepsList.forEach(step => {
          if (stepWithValues.step_id === step.id && stepWithValues.form.id === step.form_id) {
            stepWithValues.application_fields.forEach(item => {
              step.preparedFields.forEach(field => {
                if (field.id === item.field_id) {
                  if (field.type === 'file_upload') {
                    field.value = item.files.map(item => item.path) || []
                    field.files = item.files || []
                  } else if (field.type === 'payment_field') {
                    field.payment_month_selected = item.result?.payment_month_selected
                    field.payment_plan_activated = item.result?.payment_plan_activated
                  } else {
                    field.value = designateValue(field.type, item.result, field.options)
                  }
                }
              })
            })
          }
        })

      })
    },
    CHECK_CONDITIONS: state => {
      const allFields = state.stepsList
        .filter(step => step.form)
        .map(step => step.preparedFields)
        .flat()

      state.allConditions.forEach(condition => {
        let isMatched, fromStepId, fromCrossFieldResultId
        for (let i = 0; i < condition.scoped_conditions.length; i++) {
          const scopeItems = condition.scoped_conditions[i];

          const scopeItemsResults = scopeItems.filter(item => item.cross_program_id == null).map(item => {
            const foundedField = allFields.find(field => field.id === item.field_id)
            let result = null
            if (foundedField.type === "group_selection") {
              result = hasValue(item, Array.isArray(foundedField.value) ? foundedField.value.map(value => value.name) : foundedField.value)
            } else {
              result = hasValue(item, foundedField.value)
            }
            if (result) {
              fromStepId = foundedField.step_id
            }
            return result
          })

          const scopeItemsResultsCrossProgram = scopeItems.filter(item => item.cross_program_id).map(item => {
            const foundedField = state.userFieldResults.find(field => field.id === item.id)
            let result = null
            if (!foundedField) {
              return result
            }
            if (foundedField.type === "group_selection") {
              result = hasValue(item, Array.isArray(foundedField.value) ? foundedField.value.map(value => value.name) : foundedField.value)
            } else {
              result = hasValue(item, foundedField.value)
            }
            if (result) {
              fromCrossFieldResultId = foundedField.field_result_id
            }
            return result
          })

          isMatched = (scopeItemsResults.length && scopeItemsResults.every(i => i === true)) ||
            (scopeItemsResultsCrossProgram.length && scopeItemsResultsCrossProgram.every(i => i === true))

          if (isMatched || i == condition.scoped_conditions.length - 1) {

            if (condition.related_type === 'Step') {
              const stepIndex = state.stepsList.findIndex(step => step.id === condition.related_id)
              const step = state.stepsList.find(step => step.id === condition.related_id)
              const fromStep = state.stepsList.find(step => step.id === fromStepId)
              // const updatedStepData = JSON.parse(JSON.stringify(step))
              const updatedStepData = step
              if (updatedStepData) {
                if (condition.action === 'Show') {
                  updatedStepData.hidden = !isMatched
                  if (updatedStepData.status !== 'Approved') {
                    updatedStepData.status = ''
                  }
                  if (updatedStepData.status === 'Approved' && updatedStepData.hidden_value_in_db) {
                    updatedStepData.status = ''
                  }
                } else if (checkIfStepCanBeHidden(updatedStepData, fromStep)) {
                  updatedStepData.hidden = isMatched
                  updatedStepData.hiddenByStep = fromStepId
                  updatedStepData.hiddenByCrossFieldResult = fromCrossFieldResultId
                }
                Vue.set(state.stepsList, stepIndex, updatedStepData)
              }
            }

            if (condition.related_type === 'Field') {
              const field = state.allFormFields.find(field => field.id === condition.related_id)

              if (field) {
                const fieldStepIndex = state.stepsList.findIndex(step => step.form?.id === field.form_id)
                const updatedFieldStep = state.stepsList.find(step => step.form?.id === field.form_id)
                // const updatedFieldStep = JSON.parse(JSON.stringify(fieldStep))
                const updatedField = updatedFieldStep.preparedFields.find(field => field.id === condition.related_id)

                updatedField.hidden = !!(condition.action === 'Show' ? !isMatched : isMatched)

                if (!isMatched) {
                  updatedField.value = designateValue(updatedField.type, null, field.options)
                }

                if (updatedFieldStep.preparedFields.every(item => item.hidden === true) && checkIfStepCanBeHidden(updatedFieldStep)) {
                  updatedFieldStep.hidden = true
                  updatedFieldStep.hiddenByStep = fromStepId
                }
                Vue.set(state.stepsList, fieldStepIndex, updatedFieldStep)
              }
            }
            break
          }
        }
      })
    },
    CHECK_IF_ALL_FIELDS_IN_STEP_ARE_HIDDEN: state => {
      state.stepsList.forEach(step => {
        if (step.preparedFields && step.preparedFields.every(item => item.hidden === true)) {
          step.hidden = true
        }
      })
    },
    SET_APPLICATION_STATUS: (state, data) => {
      state.applicationStatus = data
    },
    SET_IS_APPLICATION_ACCEPTED: (state, data) => {
      state.isApplicationAccepted = data
    },
    SET_PAYMENT: (state, data) => {
      state.paymentContract = JSON.parse(JSON.stringify(data))

      if (state.applicationPaymentOption) {
        state.paymentContract.options = data.options.filter(item => item.id == state.applicationPaymentOption.condition_id)
        state.paymentContract.selectedOption = Number(state.applicationPaymentOption.condition_id)
        state.paymentContract.options[0].amountOfPayments = state.applicationPaymentOption.count_of_payments
        state.paymentContract.options[0].startDate = moment(state.applicationPaymentOption.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')
        state.paymentContract.options[0].selectedMethod = state.applicationPaymentOption.payment_method
      } else {
        state.paymentContract.options = data.options.filter(item => item.status === 1 && (item.user_id === null || item.user_id === state.userId))

        state.paymentContract.selectedOption = state.paymentContract.options[0].id

        state.paymentContract.options.forEach(option => {
          if (option.defaults) {
            option.amountOfPayments = option.defaults.paymentAmount
            option.selectedMethod = option.defaults?.selectedMethod
            if (option.defaults.startDate) {
              option.startDate = moment(option.defaults.startDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
            }
          } else {
            if (option.schedule_option.timeSchedule == 'Split over') {
              option.amountOfPayments = option.schedule_option.paymentAmountRange.from
              option.startDate = null
            } else {
              option.amountOfPayments = 1
            }

            if (option.method.includes('Credit Card')) {
              option.selectedMethod = 'Credit Card'
            } else {
              option.selectedMethod = option.method[0]
            }
          }

          option.schedule_option.endDate = moment(option.schedule_option.endDate).format('MM/DD/YYYY')
        })
      }
    },
    SET_STEP_STATUS: (state) => {
      state.stepsValues.forEach(item => {
        state.stepsList.forEach(step => {
          if (step.id === item.step_id) {
            step.status = item.step_status
            step.info_message = item.info_message
            step.hidden = item.is_hidden
            step.hidden_value_in_db = item.is_hidden
            step.is_last_submitted_step = item.is_last_submitted_step
          }
        })
      })
    },
    SET_CHOSEN_SESSIONS: (state, data) => {
      state.chosenSessions = data
    },
    UPDATE_ALL_SELECTED_GROUPS: (state, addedGroups = []) => {
      state.allSelectedGroups = []
      state.selectedGroupsList.forEach(group => {
        state.allSelectedGroups.push(group)
      })
      addedGroups.forEach(newGroup => {
        const group = state.allSelectedGroups.find(group => group.id === newGroup.id)
        if (!group) {
          state.allSelectedGroups.push({
            id: newGroup.id,
            name: newGroup.name,
            price: newGroup.price,
            type: newGroup.type,
            has_tuition_fee: newGroup.has_tuition_fee,
          })
        }
      })
    },
    SET_GROUPS_FOR_AUTO_ASSIGN: (state, data) => {
      data.reduce((options, group) => {
        group.price = group.sessions.reduce((totalPrice, session) => {
          totalPrice += session.pivot_price
          return totalPrice
        }, 0)
        options.push(group)
        return options
      }, []).forEach(newGroup => {
        state.groupsForAutoAssign.push({
          id: newGroup.id,
          name: newGroup.name,
          price: newGroup.price,
          type: newGroup.type,
          has_tuition_fee: newGroup.has_tuition_fee,
        })
      })
    },
    RESET_STATE(state) {
      Object.assign(state, defaultState())
    },
    SET_TAB_INDEX: (state, data) => {
      if (data >= 0) {
        state.currentTabIndex = data
      }
    },
    INCREMENT_TAB_INDEX: (state) => {
      state.currentTabIndex++
    },
    DECREMENT_TAB_INDEX: (state) => {
      state.currentTabIndex--
    },
    SET_SELECTED_GROUP_FOR_VIEWING_USERS: (state, groupId) => {
      state.selectedGroupForViewingUsers = groupId
    },
    SET_APPLICATION_PAYMENT_OPTION: (state, data) => {
      state.applicationPaymentOption = data
      if (state.applicationPaymentOption) {
        state.paymentContractIsExist = true
        state.paymentContract.options = state.paymentContract.options.filter(item => item.id == state.applicationPaymentOption.condition_id)
        state.paymentContract.selectedOption = Number(state.applicationPaymentOption.condition_id)
        state.paymentContract.options[0].amountOfPayments = state.applicationPaymentOption.count_of_payments
        state.paymentContract.options[0].startDate = moment(state.applicationPaymentOption.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')
        state.paymentContract.options[0].selectedMethod = state.applicationPaymentOption.payment_method
      }
    },
    DISABLE_EDITING_STEPS_IF_PAYMENT_CONTRACT_IS_SELECTED: state => {
      if (state.paymentContractIsExist) {
        for (let i = 0; i < state.stepsList.length; i++) {
          state.stepsList[i].isDisabledEditingByPc = true
          if (state.stepsList[i].hasPC) break
        }
      }
    },
    SET_IS_DATA_FULL_PRAPARED: (state, val) => {
      state.isDataFullPrepared = val
    },
  },
  actions: {
    async prepareFamilyForm({ state, commit }, params) {
      try {
        commit('SET_FAMILY_SETTINGS', params.family_settings)
        commit('SET_FAMILY_FORM', params.family_form)
        if (state.familyData.secondary_parent) {
          commit('ADD_SECONDARY_PARENT')
        }
        commit('SET_FAMILY_FORM_VALUES')
      } catch (error) {
        return error
      }
    },
    fetchUserAttributes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user-attributes', {
            params: queryParams,
          })
          .then(response => {
            ctx.commit('SET_USER_ATTRIBUTES', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchFamilyData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/family-data', {
            params: queryParams,
          })
          .then(response => {
            ctx.commit('SET_FAMILY_DATA', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchApplicationGroupOptions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/applications/groups`, queryParams)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    saveProgress(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/applications/save-progress', queryParams)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    saveFamilyForm(ctx, queryParams) {
      return new Promise((resolve) => {
        axios
          .post('/family-form/save-data', queryParams)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            const { response } = error
            resolve(response)
            return response
          })
      })
    },
    fetchGroupUsersList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/applications/group-members', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAutoAssignedGroupsList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/applications/auto-assigned-groups', {
            params: queryParams,
          })
          .then(response => {
            ctx.commit('SET_GROUPS_FOR_AUTO_ASSIGN', response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    postApplicationPayCondition({commit}, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/application-pay-conditions`, queryParams)
          .then(response => {
            commit('SET_APPLICATION_PAYMENT_OPTION', response.data.data)
            commit('DISABLE_EDITING_STEPS_IF_PAYMENT_CONTRACT_IS_SELECTED')
            resolve(response.data.data)
          })
          .catch(error => reject(error))
      })
    },
    fetchUserFieldResults(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user-field-results', queryParams)
          .then(response => {
            ctx.commit('SET_USER_FIELD_RESULTS', response.data.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}