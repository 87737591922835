import moment from 'moment'

const hasValue = (condition, value) => {
  let hasValue = false

  switch (condition.operator) {
    case '==':
      if (condition.value == value || (Array.isArray(value) && value.length === 1 && value.includes(condition.value))) {
        hasValue = true
      }
      break;
    case '!=':
      if (condition.value !== value || (Array.isArray(value) && value.length === 1 && !value.includes(condition.value))) {
        hasValue = true
      }
      break;
    case '==contains':
      if (Array.isArray(condition.value)) {
        if (Array.isArray(value)) {
          const found = value.some(r => condition.value.includes(String(r)))
          if (found) {
            hasValue = true
          }
        } else {
          if (condition.value.includes(String(value))) {
            hasValue = true
          }
        }
      } else {
        if (value.includes(condition.value)) {
          hasValue = true
        }
      }
      break;
    case '!=contains':
      if (value && Array.isArray(value) && !value.includes(condition.value)) {
        hasValue = true
      }
      break;
    case '==empty':
      if (!value) {
        hasValue = true
      }
      break;
    case '!=empty':
      if (value) {
        hasValue = true
      }
      break;
    case '<':
      if (+condition.value < +value) {
        hasValue = true
      }
      break;
    case '>':
      if (+condition.value > +value) {
        hasValue = true
      }
      break;

    case 'is_at':
      if (value) {
        hasValue = moment(value).isSame(new Date(condition.value), 'day')
      }
      break;

    case 'is_before':
      if (value) {
        hasValue = moment(value).isBefore(new Date(condition.value), 'day')
      }
      break;

    case 'is_before_or_at':
      if (value) {
        hasValue = moment(value).isSameOrBefore(new Date(condition.value), 'day')
      }
      break;

    case 'is_after':
      if (value) {
        hasValue = moment(value).isAfter(new Date(condition.value), 'day')
      }
      break;

    case 'is_after_or_at':
      if (value) {
        hasValue = moment(value).isSameOrAfter(new Date(condition.value), 'day')
      }
      break;

    case 'is_not_at':
      if (value) {
        hasValue = !moment(value).isSame(new Date(condition.value), 'day')
      }
      break;

    case 'is_today':
      if (value) {
        const today = new Date()
        const date = new Date(value)

        hasValue = today.toDateString() === date.toDateString()
      }
      break;

    case 'is_yesterday':
      if (value) {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const date = new Date(value)

        hasValue = yesterday.toDateString() === date.toDateString()
      }
      break;

    case 'is_this_week':
      if (value) {
        hasValue = moment(value).isSame(new Date(), 'week')
      }
      break;

    case 'is_this_month':
      if (value) {
        hasValue = moment(value).isSame(new Date(), 'month')
      }
      break;

    case 'is_this_year':
      if (value) {
        hasValue = moment(value).isSame(new Date(), 'year')
      }
      break;

    case 'is_last_month':
      if (value) {
        const startOfMonth = moment().subtract(1, 'months').startOf('month')
        const endOfMonth = moment().subtract(1, 'months').endOf('month')
        hasValue = moment(value).isBetween(startOfMonth, endOfMonth)
      }
      break;

    case 'is_next_month':
      if (value) {
        const startOfMonth = moment().add(1, 'months').startOf('month')
        const endOfMonth = moment().add(1, 'months').endOf('month')
        hasValue = moment(value).isBetween(startOfMonth, endOfMonth)
      }
      break;
  }

  return hasValue
}
const designateValue = (type, def, options = []) => {
  if (type === 'group_selection' && def !== 'not_accepted') {
    return def
  }

  if (def && def !== 'not_accepted') {
    if (type === 'checkbox' || type === 'multi_select') return def
    if (type === 'file_upload') return []

    if(type === 'dropdown' && options.length) {
      const availableValues = options.map(option => option.value)
      if (availableValues.some(obj => obj == def)) {
        return def
      }
      return null
    }

    return def
  }

  if (type === 'checkbox' || type === 'multi_select') return []
  if (type === 'dropdown') return null
  if (type === 'file_upload') return []

  return null
}
const designateOptions = (type, options) => {
  if (type === 'dropdown') {
    return options.reduce((arr, item) => {
      arr.push({ value: item, text: item })
      return arr
    }, [{ value: null, text: 'Select option' }])
  }

  return options
}
const transformField = type => {
  switch (type) {
    case 'short_text':
      return 'text'
    case 'phone':
      return 'tel'
    default:
      return type
  }
}
const checkHiddenAttr = obj => {
  return obj.length > 0
}

const checkIfStepCanBeHidden = (step, fromStep = null) => {
  return !step.status || ['Approved', 'Draft'].includes(step.status) || ['Rejected', 'Draft'].includes(fromStep?.status)
}

export {
  hasValue,
  designateValue,
  designateOptions,
  transformField,
  checkHiddenAttr,
  checkIfStepCanBeHidden,
}
